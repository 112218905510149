/** @format */

.footer {
	background-color: #1c1c1e; /* Deep dark background */
	color: #d1d1d1;
	padding: 40px 0; /* Proper spacing */
}

.footer-heading {
	font-size: 1.25rem;
	color: #ffffff;
	font-weight: 600;
	margin-bottom: 15px;
}

.contact-us {
	height: 100%; /* Ensures column takes full height */
}

.footer p {
	font-size: 0.95rem;
	margin-bottom: 10px;
	color: #d1d1d1;
}

.footer-link {
	color: #ffc107;
	text-decoration: none;
	font-weight: 500;
}

.footer-link:hover {
	color: #ffffff;
	text-decoration: underline;
}

.map-container {
	border: 2px solid #343a40;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.footer-divider {
	border-color: #343a40;
	margin: 20px 0;
}

.footer-copy {
	font-size: 0.85rem;
	color: #aaaaaa;
}

.footer-copy a {
	color: #ffc107;
	text-decoration: none;
}

.footer-copy a:hover {
	color: #ffffff;
	text-decoration: underline;
}
