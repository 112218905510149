/**
 * Header
 *
 * @format
 */

/* Hero Section */
.hero-section {
	position: relative;
	text-align: center;
	color: white;
}

.hero-section img {
	width: 100%;
	height: auto;
	opacity: 0.8;
}

.hero-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 900px;
	width: 100%;
	padding: 20px;
}

.hero-content h1 {
	font-size: 2.5rem;
	font-weight: 700;
}

.hero-content p {
	font-size: 1.2rem;
	margin: 10px 0 20px;
}

.hero-content .btn-login {
	background-color: #ffd700;
	border: none;
	padding: 10px 20px;
	font-size: 1rem;
	font-weight: 600;
	color: #2c3e50;
}
/* About Us */
.about-us {
	background-color: #f0f0f0;
	padding: 50px 20px;
	display: flex;
	justify-content: center; /* Center the content horizontally */
	align-items: center; /* Center the content vertically */
	flex-direction: row; /* Align items in a row */
	gap: 50px; /* Increased space between the circle and the image */
}

.about-us-content {
	background-color: #ffffff;
	border-radius: 50%;
	width: 800px; /* Further increased size */
	height: 800px; /* Match the width */
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 40px; /* Increased padding inside the circle */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: for visual enhancement */
	overflow: hidden; /* Hide overflow content */
}

.about-us-content h2 {
	font-size: 2.2rem; /* Further adjusted size for better fit */
	font-weight: 700;
	margin-bottom: 25px; /* Adjusted margin */
}

.about-us-content p {
	font-size: 1rem; /* Further increased font size */
	line-height: 1.7; /* Adjusted line height */
	margin: 0; /* Remove default margin */
	padding: 0; /* Remove padding */
}

.about-us-image {
	display: flex;
	justify-content: center; /* Center the image horizontally */
	align-items: center; /* Center the image vertically */
}

.about-us-image img {
	width: 150px; /* Adjusted size */
	height: 150px; /* Match the width */
	border-radius: 50%;
	border: 5px solid #ffd700;
}

/* Media Queries */
@media (max-width: 768px) {
	.about-us-content {
		width: 500px; /* Adjust size for smaller screens */
		height: 500px;
		padding: 20px; /* Adjust padding */
	}

	.about-us-image {
		margin-left: 0; /* No margin, stack vertically */
	}
}

@media (max-width: 480px) {
	.about-us-content {
		width: 350px; /* Further adjust size for very small screens */
		height: 350px;
		padding: 15px; /* Adjust padding */
	}

	.about-us-image {
		margin-left: 0; /* No margin, stack vertically */
	}
}

/* Services */
.services-section {
	padding: 50px 0;
	background-color: #f9f9f9;
}

.services-section h2 {
	font-size: 24px;
	color: #333;
}

.service-card {
	border: none;
	transition: transform 0.3s ease;
}

.service-card:hover {
	transform: translateY(-10px);
}

.service-icon {
	width: 50px;
	height: 50px;
	background-color: #ccc;
	border-radius: 50%;
	margin: 0 auto;
}

.service-card .card-title {
	font-size: 18px;
	color: #555;
}

.service-card .card-text {
	font-size: 14px;
	color: #777;
}

.view-more-btn {
	padding: 10px 20px;
	background-color: #007bff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.view-more-btn:hover {
	background-color: #0056b3;
}
/* Meet Our Doctors */
.doctors-section {
	padding: 50px 0;
	background-color: #2c3e50;
	color: #fff;
}

.doctors-section h2 {
	font-size: 24px;
	color: #fff;
}

.doctor-card {
	background-color: #34495e;
	border: none;
}

.doctor-icon-1 {
	width: 200px;
	height: 200px;
	background-color: #fff;
	border-radius: 10px;
	margin: 0 auto 20px;
	background-image: url("../../public/DrSaritha.png"); /* Replace with the path to your icon */
	background-size: cover;
	background-position: center;
}

.doctor-icon {
	width: 200px;
	height: 200px;
	background-color: #fff;
	border-radius: 10px;
	margin: 0 auto 20px;
	background-image: url("../../public/doccartoon.jpg"); /* Replace with the path to your icon */
	background-size: cover;
	background-position: center;
}

.doctor-card .card-title {
	font-size: 18px;
	color: black;
}

.doctor-card .card-text {
	font-size: 14px;
	color: black;
}
