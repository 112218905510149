/**
 * Style for the Navbar
 *
 * @format
 */

.custom-navbar {
	background-color: #212529; /* Solid dark background */
	padding: 15px 0;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

/* Logo styling */
.logo {
	border-radius: 10px; /* Rounded corners for the logo */
	transition: transform 0.3s ease; /* Smooth scaling effect */
}

.logo:hover {
	transform: scale(1.1); /* Slight zoom effect on hover */
}

/* Navigation links */
.nav-links {
	display: flex;
	gap: 15px; /* Spacing between nav items */
}

.nav-item {
	color: #f0f0f0; /* Light color for text */
	font-weight: 500;
	font-size: 1.1rem;
	transition: color 0.3s ease, transform 0.2s ease;
}

.nav-item:hover {
	color: #ffdd00; /* Highlight color on hover */
	transform: scale(1.1); /* Slight zoom effect */
	text-decoration: underline;
}

/* Responsive navbar adjustments */
@media (max-width: 768px) {
	.custom-navbar {
		padding: 10px;
	}
	.logo {
		height: 60px;
		width: 70px;
	}
	.nav-item {
		font-size: 1rem;
	}
}
